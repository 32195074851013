<template>
  <v-container class="py-0 py-sm-8 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col cols="6" class="col-sm-2">
        <v-card :class="[stockManager ? 'green' : 'grey']" class="d-flex align-center justify-center pa-4 fill-width fill-height caption font-weight-bold" dark outlined @click.prevent="stockManager = !stockManager">
          <v-icon small class="mr-2">
            {{ stockManager ? 'mdi-eye' : 'mdi-eye-off' }}
          </v-icon>
          Stock History
        </v-card>
      </v-col>
      <v-col v-if="!stockManager" cols="6" class="d-flex jutify-space-between col-sm-3">
        <v-card class="d-flex align-center justify-center pa-4 fill-width fill-height blue caption font-weight-bold" dark outlined @click.prevent="PRODUCT_VIEW()">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Product
        </v-card>
        <!-- <v-card v-if="$role([], $store.state.user.role)" class="d-flex align-center justify-center pa-4 fill-width fill-height orange caption font-weight-bold" dark outlined @click.prevent="ImportXlsx()">
          Import & Reset
        </v-card> -->
      </v-col>
      <v-col v-else cols="6" class="col-sm-2">
        <v-card class="d-flex align-center justify-center pa-4 fill-width fill-height purple caption font-weight-bold" dark outlined @click.prevent="STOCK_REQUEST_VIEW()">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Stock Request
        </v-card>
      </v-col>
      <v-col cols="12" class="white" :class="[stockManager ? 'col-sm-4' : 'col-sm-4']">
        <div class="pa-4">
          <v-text-field
            v-if="!stockManager"
            v-model="tableSearch"
            outlined
            dense
            hide-details
            placeholder="Cari produk (Name, SKU, Tags)"
            prepend-inner-icon="mdi-magnify"
            append-icon="mdi-barcode"
          />
          <v-text-field
            v-else
            v-model="tableSearchSR"
            outlined
            dense
            hide-details
            placeholder="Cari ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col :class="[stockManager ? 'col-sm-4' : 'col-sm-3']" cols="12" class="white">
        <div class="pa-4">
          <v-select
            v-if="stockManager"
            v-model="tableInOutSR"
            :items="[{ name: 'Semua Request', value: null }, { name: 'Permintaan Masuk (IN)', value: 'IN' }, { name: 'Permintaan Keluar (OUT)', value: 'OUT' }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            label="Request"
            :disabled="!$role(['manager', 'warehouse'], $store.state.user.role)"
          />
          <v-select
            v-else
            v-model="productStatus"
            :items="[{ name: 'Semua Status', value: '' }, { name: 'Aktif', value: 1 }, { name: 'Tidak Aktif', value: 0 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            label="Status"
            :disabled="!$role(['manager', 'warehouse'], $store.state.user.role)"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          v-show="!stockManager"
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [10, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.name`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn depressed dark small color="blue" class="rounded-0 text-capitalize mr-2" @click.prevent="PRODUCT_VIEW(item)">
                <v-icon small class="mr-1">
                  mdi-package
                </v-icon>
                {{ item.product_name }}
              </v-btn>
              <span :class="[parseInt(item.status) ? '' : 'red--text']">{{ item.name }}</span>
            </div>
          </template>
          <template v-slot:[`item.sku`]="{ item }">
            <div style="min-width: 100px;">
              {{ item.sku }}
            </div>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <div style="min-width: 100px;">
              {{ $price(item.price) }}
            </div>
          </template>
          <template v-slot:[`item.stock`]="{ item }">
            <div style="min-width: 100px;">
              {{ parseInt(item.stock_in) - (parseInt(item.stock_out) + parseInt(item.stock_out_trx)) }}
            </div>
          </template>
          <template v-slot:[`item.stock_out`]="{ item }">
            <div style="min-width: 100px;">
              {{ parseInt(item.stock_out_trx || 0) + parseInt(item.stock_out || 0) }} ({{ parseInt(item.stock_out_trx || 0) }}/{{ parseInt(item.stock_out || 0) }})
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['manager', 'warehouse'], $store.state.user.role)" depressed x-small dark color="orange" class="text-capitalize" @click.prevent="PRODUCT_ITEM_VIEW(item, 0, true)">
                <v-icon x-small class="mr-2">
                  mdi-pencil
                </v-icon>
                Ubah
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <v-data-table
          v-show="stockManager"
          dense
          :headers="headersSR"
          :items="tableSR"
          item-key="id"
          :server-items-length="tableTotalSR"
          :page.sync="tablePageSR"
          :items-per-page.sync="tableLimitSR"
          :options.sync="optionsSR"
          :footer-props="{
            itemsPerPageOptions: [10, 50, 100]
          }"
          :loading="tableLoadingSR"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div :class="[item.store_from === $store.state.storeActive ? 'red' : 'blue']" class="pa-2 text-center darken-2 white--text caption">
              <strong>#{{ item.stock_request }}</strong>/{{ item.id }}
            </div>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <div class="caption">
              {{ $localDT(item.created, 'datetimedefault') }}
            </div>
          </template>
          <template v-slot:[`item.request`]="{ item }">
            <div class="lh-0 py-2">
              <div v-if="item.store_from === $store.state.storeActive">
                <small>
                  {{ item.store_from ? item.store_from_name : 'Lainnya' }}
                </small>
                <v-icon c-small class="mx-2 caption" color="red">
                  mdi-transfer-right
                </v-icon>
                <small>
                  {{ item.store_to ? item.store_to_name : 'Lainnya' }}
                </small>
              </div>
              <div v-else>
                <small>
                  {{ item.store_to ? item.store_to_name : 'Lainnya' }}
                </small>
                <v-icon c-small class="mx-2 caption" color="blue">
                  mdi-transfer-left
                </v-icon>
                <small>
                  {{ item.store_from ? item.store_from_name : 'Lainnya' }}
                </small>
              </div>
              <div class="font-italic caption lh-0">
                <v-icon x-small class="mr-1">
                  mdi-comment-outline
                </v-icon>
                {{ item.stock_request_title }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.product`]="{ item }">
            <div class="lh-0">
              {{ item.product_name + ' - ' + item.product_item_name }}
              <small class="d-block font-weight-bold grey--text text--lighten-1 mt-1">
                SKU: {{ item.product_item_sku }}
              </small>
            </div>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <div style="min-width: 100px;">
              {{ item.quantity }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div v-if="stockManager" class="caption">
              <div v-if="!parseInt(item.store_from) || !parseInt(item.store_to)">
                <v-chip color="green" x-small dark>
                  Selesai
                </v-chip>
              </div>
              <div v-else-if="StatusRequest(parseInt(item.stock_request_status), parseInt(item.status)).response" class="d-flex align-center">
                <v-chip :color="StatusRequest(parseInt(item.stock_request_status), parseInt(item.status)).request.color" x-small dark class="mr-1">
                  {{ StatusRequest(parseInt(item.stock_request_status), parseInt(item.status)).request.message }}
                </v-chip>/
                <v-chip :color="StatusRequest(parseInt(item.stock_request_status), parseInt(item.status)).product.color" x-small dark class="ml-1">
                  {{ StatusRequest(parseInt(item.stock_request_status), parseInt(item.status)).product.message }}
                </v-chip>
              </div>
              <div v-else>
                <v-chip :color="StatusRequest(parseInt(item.stock_request_status), parseInt(item.status)).color" x-small dark>
                  {{ StatusRequest(parseInt(item.stock_request_status), parseInt(item.status)).message }}
                </v-chip>
              </div>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn depressed x-small dark color="orange" class="text-capitalize" @click.prevent="STOCK_REQUEST_VIEW(item.stock_request)">
                <v-icon x-small class="mr-2">
                  mdi-eye
                </v-icon>
                Lihat
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogProduct.show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          class="elevation-0"
        >
          <v-btn
            icon
            dark
            @click="dialogProduct.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ dialogProduct.data ? parseInt(dialogProduct.data.id) ? 'Update Product' : 'Create Product' : 'Undefined' }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <!-- <v-btn
              dark
              text
              @click="dialogProduct.show = false"
            >
              Save
            </v-btn> -->
          </v-toolbar-items>
        </v-toolbar>
        <v-container v-if="dialogProduct.data">
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-6 pa-4">
              Product Detail
              <v-divider class="my-4" />
              <v-select
                v-model="dialogProduct.data.stores"
                :items="$store.state.storeData"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
                label="Aktifkan pada Toko :"
                class="mb-4"
                multiple
                persistent-hint
                :disabled="!$role(['manager', 'warehouse'], $store.state.user.role)"
              ></v-select>
              <v-text-field
                v-model="dialogProduct.data.name"
                outlined
                dense
                hide-details
                label="Nama Produk"
                class="mb-4"
                :disabled="!$role(['manager', 'warehouse'], $store.state.user.role)"
              />
              <v-select
                v-model="dialogProduct.data.category"
                :items="$store.state.categories"
                item-text="name"
                item-value="name"
                outlined
                dense
                hide-details
                label="Kategori"
                class="mb-4"
                :disabled="!$role(['manager', 'warehouse'], $store.state.user.role)"
              ></v-select>
              <v-textarea
                v-model="dialogProduct.data.description"
                outlined
                dense
                hide-details
                label="Keterangan"
                class="mb-4"
                :disabled="!$role(['manager', 'warehouse'], $store.state.user.role)"
              />
              <v-select
                v-model.number="dialogProduct.data.featured"
                outlined
                dense
                hide-details
                :items="[
                  { name: 'Tidak', value: 0 },
                  { name: 'Ya', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                label="Tampilkan sebagai Produk Unggulan?"
                class="mb-4"
              />
              <v-select
                v-model.number="dialogProduct.data.status"
                outlined
                dense
                hide-details
                :items="[
                  { name: 'Tidak', value: 0 },
                  { name: 'Ya', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                label="Tampilkan pada Aplikasi?"
                class="mb-4"
              />
              <!-- <Tags v-model="dialogProduct.data.tags" /> -->
              <!-- <v-select
                v-model.number="dialogProduct.data.featured"
                outlined
                dense
                hide-details
                :items="[
                  { name: 'Tidak', value: 0 },
                  { name: 'Ya', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                label="Featured Product"
                class="mb-4"
                :disabled="!$role(['manager', 'warehouse'], $store.state.user.role)"
              /> -->
              <!-- <v-select
                v-model.number="dialogProduct.data.status"
                outlined
                dense
                hide-details
                :items="[
                  { name: 'Tidak', value: 0 },
                  { name: 'Ya', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                label="Tampilkan pada web ?"
                class="mb-4"
                :disabled="!$role(['manager', 'warehouse'], $store.state.user.role)"
              /> -->
            </v-col>
            <v-col cols="12" class="col-sm-6 pa-4">
              <div class="d-flex fill-width justify-space-between">
                Product Items (variant)
                <v-btn v-if="$role(['manager', 'warehouse'], $store.state.user.role)" color="orange" dark small depressed class="text-capitalize  caption rounded-0" @click.prevent="PRODUCT_ITEM_VIEW(null, -1)">
                  Add
                </v-btn>
              </div>
              <v-divider class="mt-3 mb-4" />
              <div>
                <div v-for="(i, iI) in dialogProduct.items" :key="'item-' + iI" class="d-flex fill-width justify-space-between mb-4 pa-2 grey lighten-4">
                  <div class="d-flex">
                    <v-img
                      :src="$getImage(i.galleries)"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      style="width: 100px!important"
                    />
                    <div class="pl-4">
                      <div class="grey--text text-uppercase">
                        <v-icon class="mr-1">
                          mdi-barcode
                        </v-icon>
                        <span class="caption">
                          {{ i.sku }}
                        </span>
                      </div>
                      <div class="font-weight-bold">
                        {{ i.name }}
                      </div>
                      <div class="font-weight-bold">
                        {{ $price(i.price) }}
                      </div>
                    </div>
                  </div>
                  <div v-if="$role(['manager', 'warehouse'], $store.state.user.role)">
                    <v-btn v-if="!i.id" icon color="red" small @click.prevent="dialogProduct.items.splice(iI, 1)">
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                    <v-btn icon color="blue" small @click.prevent="PRODUCT_ITEM_VIEW(i, iI)">
                      <v-icon>
                        mdi-pencil-circle
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col v-if="$role(['manager', 'warehouse'], $store.state.user.role)" cols="12" class="text-center">
              <v-divider class="mt-4 mb-8" />
              <v-btn :disabled="loadingProcess" depressed color="blue" dark class="text-capitalize" @click.prevent="PRODUCT_PROCESS()">
                {{ loadingProcess ? 'Please Wait' : 'Simpan' }}
               </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogProductItem.show"
      persistent
      max-width="840"
      scrollable
    >
      <v-card>
        <v-card-title v-if="dialogProductItem.data">
          {{ dialogProductItem.index > -1 || parseInt(dialogProductItem.data.id) ? 'Update Variant' : 'New Variant' }}
        </v-card-title>
        <v-divider />
        <v-card-text v-if="dialogProductItem.data" class="py-4" style="height: calc(100% - 102px);">
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-4">
              <v-text-field
                v-model="dialogProductItem.data.sku"
                outlined
                dense
                hide-details
                prepend-inner-icon="mdi-barcode"
                label="SKU"
                class="mb-4"
              />
              <v-text-field
                v-model="dialogProductItem.data.name"
                outlined
                dense
                hide-details
                label="Nama Variant"
                class="mb-4"
              />
              <v-text-field
                v-model.number="dialogProductItem.data.weight"
                outlined
                dense
                hide-details
                label="Berat/Volume Pengiriman"
                suffix="gram"
                class="mb-4"
                @keypress="$NumberOnly"
              />
              <!-- <Tags v-model="dialogProductItem.data.tags" /> -->
              <v-divider class="mb-4" />
              <v-text-field
                v-model.number="dialogProductItem.data.price"
                outlined
                dense
                hide-details
                label="Harga Default (Display/Label)"
                prefix="Rp"
                class="mb-4"
                @keypress="$NumberOnly"
              />
              <!-- <v-select
                v-model.number="dialogProductItem.data.featured"
                outlined
                dense
                hide-details
                :items="[
                  { name: 'Tidak', value: 0 },
                  { name: 'Ya', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                label="Tampilkan sebagai Produk Unggulan?"
                class="mb-4"
              />-->
              <v-select
                v-model.number="dialogProductItem.data.status"
                outlined
                dense
                hide-details
                :items="[
                  { name: 'Tidak Aktif', value: 0 },
                  { name: 'Aktif', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                label="Status"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-8 px-0 px-sm-4">
              <v-icon small class="mr-2 ">
                mdi-image-multiple
              </v-icon>
              Galeri Variant
              <v-divider class="mt-2" />
              <v-row class="py-2 px-1">
                <v-col cols="6" class="col-sm-4 pa-2" style="position: relative;" @click.prevent="UploadImage()">
                  <v-img
                    aspect-ratio="1"
                    class="blue lighten-4 fill-width"
                  />
                  <div class="d-flex fill-width fill-height align-center justify-center caption c-pointer text-center" style="position: absolute;top: 0px;left:0px;">
                    <span v-if="loadingImageUpload">
                      uploading ...
                    </span>
                    <span v-else>
                      + Add Image<br>
                      1:1
                    </span>
                  </div>
                </v-col>
                <v-col v-for="(g, iImg) in dialogProductItem.data.galleries" :key="'gallery-' + iImg" cols="6" class="col-sm-4 pa-2 gmj-gallery" style="position: relative;" >
                  <v-img
                    :src="g"
                    aspect-ratio="1"
                    class="grey lighten-3 fill-width"
                  />
                  <div class="d-flex fill-width fill-height align-center justify-center caption c-pointer text-center gmj-gallery-action" style="position: absolute;top: 0px;left:0px;">
                    <span>
                      <v-btn x-small icon color="purple" class="mr-1" @click.prevent="changeGallery(iImg)">
                        <v-icon small color="blue">
                          mdi-refresh
                        </v-icon>
                      </v-btn>
                      <v-btn x-small icon color="red" class="ml-1" @click.prevent="removeGallery(iImg)">
                        <v-icon small color="red">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="px-0 pt-2 mt-2">
              <v-textarea
                v-model="dialogProductItem.data.description"
                outlined
                dense
                rows="3"
                hide-details
                label="Deskripsi Variant"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="px-0 px-sm-4 pt-2 mt-2 grey lighten-3">
              <div class="d-flex fill-width align-center pt-2 pb-3 font-weight-bold">
                <v-icon small class="mr-2">
                  mdi-file-tree-outline
                </v-icon>
                Skema Harga
              </div>
              <v-row no-gutters class="pb-4">
                <!--
                  [{ type: discount/gift, customer: (0: default/1: special), platform: offline/online, minimum_quantity: 1, discount: 0, gift: '' }]
                -->
                <v-col cols="12" class="col-sm-4">
                  <v-autocomplete
                    v-model="pBerjenjang.type"
                    :items="[{ name: 'Discount', value: 'discount' }, { name: 'Hadiah (Gift)', value: 'gift' }]"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    hide-details
                    label="Type"
                    class="mb-4 grey lighten-4"
                  />
                  <!-- <v-autocomplete
                    v-model="pBerjenjang.platform"
                    :items="[{ name: 'Semua (Online & Offline)', value: '' }, { name: 'Offline', value: 0 }, { name: 'Online', value: 1 }]"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    hide-details
                    label="Platform"
                    class="mb-4 grey lighten-4"
                  /> -->
                  <!-- <v-autocomplete
                    v-model="pBerjenjang.customer"
                    :items="[{ name: 'Semua', value: '' }, { name: 'Regular Customer', value: 0 }, { name: 'Special Customer', value: 1 }]"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    hide-details
                    label="Customer"
                    class="mb-4 grey lighten-4"
                  /> -->
                  <v-text-field
                    v-model="pBerjenjang.minimum_quantity"
                    outlined
                    dense
                    hide-details
                    label="Minimal Quantity"
                    suffix="SKU"
                    class="mb-4 grey lighten-4"
                    @keypress="$NumberOnly"
                  />
                  <div :class="[pBerjenjang.type === 'discount' ? 'orange' : pBerjenjang.type === 'gift' ? 'brown' : '']" class="pa-2 mb-4">
                    <v-text-field
                      v-if="pBerjenjang.type === 'discount'"
                      v-model.number="pBerjenjang.discount"
                      outlined
                      dense
                      hide-details
                      label="Discount / Potongan Harga"
                      prefix="Rp"
                      dark
                      @keypress="$NumberOnly"
                    />
                    <v-text-field
                      v-else-if="pBerjenjang.type === 'gift'"
                      v-model="pBerjenjang.gift"
                      outlined
                      dense
                      hide-details
                      dark
                      label="Keterangan Gift"
                      prepend-inner-icon="mdi-gift-open-outline"
                    />
                  </div>
                  <div :class="[pBerjenjang.indexSelected > -1 ? 'justify-space-between' : 'justify-end']" class="d-flex fill-width">
                    <v-btn v-if="pBerjenjang.indexSelected > -1" depressed dark small color="red" class="rounded-0" @click.prevent="SYNC_PBJ()">
                      Batal
                    </v-btn>
                    <v-btn :color="pBerjenjang.indexSelected > -1 ? 'indigo' : 'purple'" depressed small dark class="rounded-0" @click.prevent="PROSES_PBJ()">
                      {{ pBerjenjang.indexSelected > -1 ? 'Update Skema' : 'Buat Skema' }}
                      <v-icon x-small class="ml-1">
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" class="col-sm-8 pl-0 pl-sm-4">
                  <div class="table-pbj">
                    <table class="fill-width">
                      <thead>
                        <tr>
                          <td class="blue lighten-4">Skema</td>
                          <td class="blue lighten-4">Min.Qty</td>
                          <td class="blue lighten-4">Platform</td>
                          <td class="blue lighten-4">Customer</td>
                          <td class="blue lighten-4">Opsi</td>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(pb, iPb) in dialogProductItem.data.price_berjenjang">
                          <tr v-if="pBerjenjang.indexSelected > -1 ? pBerjenjang.indexSelected === iPb : true" :key="iPb" :class="[pBerjenjang.indexSelected === iPb ? 'pb-active' : '']">
                            <td>
                              {{ pb.type }}
                              <v-chip v-if="pb.type === 'discount'" color="orange" dark x-small class="ml-1" style="font-size: 10px;">
                                {{ $price(pb.discount) }}
                              </v-chip>
                              <v-chip v-else-if="pb.type === 'gift'" color="brown" dark x-small class="ml-1" style="font-size: 10px;">
                                {{ pb.gift }}
                              </v-chip>
                            </td>
                            <td>{{ isNaN(parseInt(pb.minimum_quantity)) ? 0 : parseInt(pb.minimum_quantity) }}</td>
                            <td>{{ (pb.platform === '' ? 'Semua' : parseInt(pb.platform) === 0 ? 'Offline' : 'Online') }}</td>
                            <td>{{ (pb.customer === '' ? 'Semua' : parseInt(pb.customer) === 0 ? 'Regular' : 'Special') }}</td>
                            <td class="">
                              <div class="d-flex fill-width justify-center">
                                <v-btn color="red" depressed dark x-small class="mr-1" @click.prevent="REMOVE_PBJ(iPb)">
                                  <v-icon x-small>
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                                <v-btn color="blue" depressed dark x-small class="ml-1" @click.prevent="SYNC_PBJ(pb, iPb)">
                                  <v-icon x-small>
                                    mdi-pencil
                                  </v-icon>
                                </v-btn>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red"
            small
            text
            dark
            class="rounded-0 text-capitalize"
            @click.prevent="dialogProductItem.show = false"
          >
            Tutup
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="pBerjenjang.indexSelected > -1"
            color="indigo darken-2"
            small
            depressed
            dark
            class="rounded-0 text-capitalize"
            @click.prevent="PRODUCT_ITEM_PROCESS()"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogStockRequest.show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          class="elevation-0"
        >
          <v-btn
            icon
            dark
            @click="dialogStockRequest.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ dialogStockRequest.data ? parseInt(dialogStockRequest.data.id) ? 'Update Stock Request' : 'Create Stock Request' : 'Undefined' }}
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-container v-if="dialogStockRequest.data">
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-6 px-4 pb-4 pt-6">
              <div class="fill-width d-flex align-center justify-space-between">
                Stock Request
                <div v-if="parseInt(dialogStockRequest.data.id)">
                  <v-chip v-if="parseInt(dialogStockRequest.data.status) === 0" small color="grey" dark>
                    Pending
                  </v-chip>
                  <v-chip v-else-if="parseInt(dialogStockRequest.data.status) === 1" small color="orange" dark>
                    Terproses
                  </v-chip>
                  <v-chip v-else-if="parseInt(dialogStockRequest.data.status) === 2" small color="green" dark>
                    Selesai
                  </v-chip>
                  <v-chip v-else small color="red" dark>
                    Dibatalkan
                  </v-chip>
                </div>
              </div>
              <v-divider class="my-5" />
              <v-row no-gutters class="pb-4">
                <v-col cols="5">
                  <v-select
                    v-model.number="dialogStockRequest.data.store_from"
                    :items="$store.state.storeData.concat([ { name: 'Lainnya', id: 0 } ])"
                    :disabled="dialogStockRequest.data.id ? true : false"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details
                    label="Dari"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="d-flex align-center justify-center">
                  <v-icon color="blue">
                    mdi-transfer-right
                  </v-icon>
                </v-col>
                <v-col cols="5">
                  <v-select
                    v-model.number="dialogStockRequest.data.store_to"
                    :items="$store.state.storeData.concat([ { name: 'Lainnya', id: 0 } ]).filter(r => r.id !== dialogStockRequest.data.store_from)"
                    :disabled="dialogStockRequest.data.id ? true : false"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details
                    label="Ke"
                  ></v-select>
                </v-col>
              </v-row>
              <v-text-field
                v-model="dialogStockRequest.data.title"
                :disabled="dialogStockRequest.data.status !== 0"
                outlined
                dense
                hide-details
                prepend-inner-icon="mdi-comment-outline"
                label="Catatan"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-6 pa-4">
              <div class="d-flex fill-width justify-space-between">
                <v-row v-if="dialogStockRequest.data.status === 0" no-gutters>
                  <v-col cols="7">
                    <v-autocomplete
                      v-model="dialogStockRequestItem.product"
                      :search-input.sync="dialogStockRequestItem.query"
                      :items="dialogStockRequestItem.result"
                      item-text="product_fullname"
                      outlined
                      dense
                      hide-details
                      return-object
                      label="Tambahkan Produk (SKU)"
                    />
                  </v-col>
                  <v-col cols="3" class="pl-2">
                    <v-text-field
                      v-model.number="dialogStockRequestItem.quantity"
                      outlined
                      dense
                      hide-details
                      label="Jumlah"
                      @keypress="$NumberOnly"
                    />
                  </v-col>
                  <v-col cols="2" class="pl-2">
                    <v-btn height="100%" depressed block color="purple" dark @click.prevent="ADD_STOCK_REQUEST_ITEM()">
                      + Add
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-else no-gutters>
                  <v-col cols="12" class="py-2 d-flex align-center justify-space-between">
                    List Stock Request
                  </v-col>
                </v-row>
                <!-- List Produk (SKU's)
                <v-btn v-if="$role(null, $store.state.user.role)" color="orange" dark small depressed class="text-capitalize  caption rounded-0" @click.prevent="PRODUCT_ITEM_VIEW(null, -1)">
                  Add
                </v-btn> -->
              </div>
              <v-divider class="mt-3 mb-4" />
              <div>
                <div v-for="(i, iI) in dialogStockRequest.items" :key="'request-item-' + iI" class="d-flex fill-width justify-space-between mb-4 pa-2 grey lighten-4">
                  <div class="d-flex">
                    <!-- <v-img
                      :src="$getImage(i.product.galleries)"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      style="width: 100px!important"
                    /> -->
                    <div class="pl-4">
                      <div class="grey--text text-uppercase">
                        <v-icon class="mr-1">
                          mdi-barcode
                        </v-icon>
                        <span class="caption">
                          {{ i.product_sku }}
                        </span>
                      </div>
                      <div class="font-weight-bold">
                        {{ i.product_fullname }}
                      </div>
                      <div class="caption">
                        Jumlah : {{ i.quantity }}
                      </div>
                    </div>
                  </div>
                  <div>
                    <v-btn v-if="!i.id" icon color="red" small @click.prevent="dialogStockRequest.items.splice(iI, 1)">
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                    <div v-else-if="((dialogStockRequest.data.status === 0 || dialogStockRequest.data.status === 1) && $store.state.storeActive === dialogStockRequest.data.store_to) || dialogStockRequest.data.store_to === 0" class="pa-2">
                      <v-btn x-small class="mr-2 text-capitalize" :depressed="i.status === 1" :outlined="i.status !== 1" :color="i.status === 1 ? 'green' : 'grey'" @click.prevent="dialogStockRequest.items[iI].status = 1" dark>
                        Terima (Ok)
                      </v-btn>
                      <v-btn x-small class="mr-2 text-capitalize" :depressed="i.status === 2" :outlined="i.status !== 2" :color="i.status === 2 ? 'red' : 'grey'" @click.prevent="dialogStockRequest.items[iI].status = 2" dark>
                        Tolak
                      </v-btn>
                    </div>
                    <div v-else class="pa-2 caption" :class="[i.status === 1 ? 'green--text' : i.status === 2 ? 'red--text' : 'grey--text']">
                      <v-chip v-if="parseInt(i.status) === 2" x-small color="red" dark>
                        Ditolak
                      </v-chip>
                      <v-chip v-else-if="parseInt(i.status) === 1" x-small color="green" dark>
                        Diterima (Ok)
                      </v-chip>
                      <v-chip v-else x-small color="grey" dark>
                        Pending
                      </v-chip>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col v-if="(dialogStockRequest.data.status === 0 || dialogStockRequest.data.status === 1)" cols="12" class="text-center">
              <v-divider class="mt-4 mb-8" />
              <div>
                <v-btn v-if="dialogStockRequest.data.id && dialogStockRequest.data.status !== 1" depressed color="red" dark class="text-capitalize mx-2" @click.prevent="STOCK_REQUEST_PROCESS(99)">
                  BATALKAN REQUEST
                </v-btn>
                <v-btn
                  v-if="(dialogStockRequest.data.status === 0 || (dialogStockRequest.data.status === 1 && $store.state.storeActive === dialogStockRequest.data.store_to)) && dialogStockRequest.data.store_from !== 0 && dialogStockRequest.data.store_to !== 0"
                  :disabled="dialogStockRequest.data.id ? ((dialogStockRequest.items.find(r => !r.status)) ? true : false) : false"
                  :color="dialogStockRequest.data.id && $store.state.storeActive === dialogStockRequest.data.store_to ? 'green' : 'blue'"
                  depressed
                  dark
                  class="text-capitalize mx-2 btn-checkout"
                  @click.prevent="STOCK_REQUEST_PROCESS(dialogStockRequest.data.id && (dialogStockRequest.data.status === 0 || dialogStockRequest.data.status === 1) && $store.state.storeActive === dialogStockRequest.data.store_to ? 1 : 0)"
                >
                  {{ dialogStockRequest.data.id && $store.state.storeActive === dialogStockRequest.data.store_to ? (dialogStockRequest.data.status === 0 ? 'PROSES REQUEST' : 'UPDATE REQUEST') : dialogStockRequest.data.id ? 'UPDATE' : 'SIMPAN' }}
                </v-btn>
                <v-btn
                  v-else
                  color="purple"
                  depressed
                  dark
                  class="text-capitalize mx-2"
                  @click.prevent="STOCK_REQUEST_PROCESS(2)"
                >
                  SELESAI
                </v-btn>
              </div>
            </v-col>
            <!-- <v-col v-else cols="12" class="text-center">
              Selesai
            </v-col> -->
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
let searchInterval = null
export default {
  name: 'Inventory',
  data: (vm) => ({
    loadingProcess: false,
    productStatus: 1,
    pBerjenjang: {
      type: 'discount',
      platform: '',
      customer: '',
      minimum_quantity: 1,
      discount: 0,
      gift: '',
      indexSelected: -1
    },
    loadingImageUpload: false,
    stockManager: false,

    headers: [
      {
        text: 'Produk',
        value: 'name'
      },
      { text: 'SKU', value: 'sku', sortable: false },
      { text: 'Harga Display', value: 'price', sortable: false },
      { text: 'Stock In', value: 'stock_in', sortable: true },
      { text: 'Stock Out (TRX/MOVING)', value: 'stock_out', sortable: true },
      { text: 'Actual Stock', value: 'stock', sortable: true },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 50,
    tableSearchStatus: '',
    options: {},
    dialogProduct: {
      show: false,
      data: {
        id: null,
        category: '',
        categorysub: '',
        name: '',
        description: '',
        galleries: [],
        best_seller: 0,
        featured: 0,
        stores: [],
        status: 0 // 0: Not Published, 1: Published, 100: Deleted
      },
      items: []
    },
    dialogProductItem: {
      show: false,
      data: {
        id: null,
        sku: '',
        product: 0,
        name: '',
        price: 0,
        price_berjenjang: [],
        weight: 0,
        volume: 0,
        specs: '',
        galleries: [],
        featured: 0,
        status: 0
      },
      index: -1,
      direct: false
    },
    galleryIndexToChange: -1,

    headersSR: [
      { text: 'Req ID / Product ID', value: 'id', sortable: false },
      { text: 'Tanggal', value: 'created', sortable: false },
      { text: 'Request', value: 'request', sortable: false },
      { text: 'Produk', value: 'product' },
      { text: 'Jumlah', value: 'quantity', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearchSR: '',
    tableLoadingSR: false,
    tableAchievementSR: 3,
    tableSR: [],
    tableTotalSR: 0,
    tablePageSR: 1,
    tableLimitSR: 50,
    tableSearchStatusSR: '',
    optionsSR: {},
    tableInOutSR: '',

    dialogStockRequest: {
      show: false,
      data: {
        id: null,
        user_requestor: vm.$store.state.user.id,
        user_approval: 0,
        title: '',
        store_from: 0,
        store_to: 0,
        status: 0
      },
      items: []
    },
    dialogStockRequestItem: {
      product: null,
      query: '',
      result: [],
      quantity: 0
    }
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    })
    // PBJSYNCEDLIST () {
    //   if (this.dialogProductItem.show) {
    //     if (this.dialogProductItem.data) {
    //       if (typeof this.dialogProductItem.data.price_berjenjang === 'object') {
    //         return this.dialogProductItem.data.price_berjenjang || []
    //       }
    //     }
    //   }
    //   return []
    // }
  },
  watch: {
    productStatus () {
      this.PRODUCT_GET(this.tableSearch)
    },
    options: {
      handler () {
        this.PRODUCT_GET(this.tableSearch)
      },
      deep: true
    },
    optionsSR: {
      handler () {
        this.STOCK_REQUEST_GET(this.tableSearchSR)
      },
      deep: true
    },
    'dialogStockRequestItem.query' (v) {
      this.dialogStockRequestItem.result = []
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.PRODUCT_GET(v, true)
      }, 300)
    },
    storeActive (v) {
      if (v) {
        this.PRODUCT_GET(this.tableSearch)
        this.STOCK_REQUEST_GET(this.tableSearchSR)
      }
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.PRODUCT_GET(q)
      }, 300)
    },
    tableSearchSR: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.STOCK_REQUEST_GET(q)
      }, 300)
    },
    tableInOutSR (v) {
      this.STOCK_REQUEST_GET(this.tableSearchSR)
    },
    stockManager: function (v) {
      if (v) {
        if (this.tableSearchSR) {
          this.tableSearchSR = ''
        } else {
          this.STOCK_REQUEST_GET('')
        }
      } else {
        if (this.tableSearch) {
          this.tableSearch = ''
        } else {
          this.PRODUCT_GET('')
        }
      }
    }
  },
  mounted () {
    if (this.tableSearch) {
      this.tableSearch = ''
    } else {
      this.PRODUCT_GET('')
    }
    this.$store.dispatch('product/PRODUCT_TAGS_GET')
  },
  methods: {
    SYNC_PBJ (data, index) {
      const updated = data ? Object.assign({}, data) : null
      this.pBerjenjang.type = updated ? updated.type || 'discount' : 'discount'
      this.pBerjenjang.platform = updated ? parseInt(updated.customer) === 0 ? 0 : parseInt(updated.customer) === 1 ? 1 : '' : ''
      this.pBerjenjang.customer = updated ? parseInt(updated.customer) === 0 ? 0 : parseInt(updated.customer) === 1 ? 1 : '' : ''
      this.pBerjenjang.minimum_quantity = updated ? parseInt(updated.minimum_quantity) || 0 : 0
      this.pBerjenjang.discount = updated ? parseInt(updated.discount) || 0 : 0
      this.pBerjenjang.gift = updated ? updated.gift || '' : ''
      this.pBerjenjang.indexSelected = updated && (index || index === 0) ? index : -1
    },
    PROSES_PBJ () {
      const pbj = Object.assign({}, this.pBerjenjang)
      if (pbj.type !== 'discount' && pbj.type !== 'gift') {
        this.$store.dispatch('TOAST', { show: true, message: 'Type belum ditentukan!' })
        return false
      } else if (pbj.platform !== '' && pbj.platform !== 0 && pbj.platform !== 1) {
        this.$store.dispatch('TOAST', { show: true, message: 'Platform belum ditentukan!' })
        return false
      } else if (pbj.customer !== '' && pbj.customer !== 0 && pbj.customer !== 1) {
        this.$store.dispatch('TOAST', { show: true, message: 'Jenis Customer belum ditentukan!' })
        return false
      } else if (isNaN(parseInt(pbj.minimum_quantity)) || (parseInt(pbj.minimum_quantity) < 0)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tentukan minimum quantity!' })
        return false
      } else if (pbj.type === 'discount' && !parseInt(pbj.discount)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tentukan jumlah discount!' })
        return false
      } else if (pbj.type === 'gift' && !pbj.gift) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tentukan keterangan Gift!' })
        return false
      }
      delete pbj.indexSelected
      if (this.pBerjenjang.indexSelected > -1) {
        this.dialogProductItem.data.price_berjenjang[this.pBerjenjang.indexSelected] = pbj
      } else {
        this.dialogProductItem.data.price_berjenjang.push(pbj)
      }
      setTimeout(() => {
        this.dialogProductItem.data.price_berjenjang = this.$sortArray(this.dialogProductItem.data.price_berjenjang, 'minimum_quantity')
        this.SYNC_PBJ()
      }, 150)
    },
    REMOVE_PBJ (index) {
      if (!confirm('Apakah anda yakin ?')) {
        return false
      }
      this.dialogProductItem.data.price_berjenjang.splice(index, 1)
      this.SYNC_PBJ()
    },
    PRODUCT_ITEM_VIEW (i, index, directUpdate) {
      this.SYNC_PBJ()
      if (i || index > -1) {
        const item = Object.assign({}, i)
        item.tags = !item.tags || typeof item.tags === 'string' ? this.$StrToArray(item.tags) : item.tags
        item.galleries = !item.galleries || typeof item.galleries === 'string' ? this.$StrToArray(item.galleries) : item.galleries
        item.price_berjenjang = !item.price_berjenjang || typeof item.price_berjenjang === 'string' ? this.$StrToArray(item.price_berjenjang) || [] : item.price_berjenjang
        item.price_berjenjang = item.price_berjenjang ? this.$sortArray(item.price_berjenjang, 'minimum_quantity') : []
        this.dialogProductItem.data = item
      } else {
        this.dialogProductItem.data = Object.assign({}, {
          id: null,
          sku: '',
          product: 0,
          name: '',
          description: '',
          price: 0,
          price_berjenjang: [],
          weight: 0,
          volume: 0,
          specs: '',
          galleries: [],
          tags: [],
          status: 1
        })
      }
      this.dialogProductItem.direct = directUpdate || false
      this.dialogProductItem.index = index
      this.dialogProductItem.show = true
    },
    PRODUCT_ITEM_PROCESS () {
      if (this.dialogProductItem.direct) {
        const params = Object.assign({}, this.dialogProductItem.data)
        params.galleries = this.$ArrayToStr(params.galleries)
        params.tags = this.$ArrayToStr(params.tags)
        params.price_berjenjang = params.price_berjenjang.map((r) => {
          r = (typeof r === 'string') ? this.$StrToJson(r) : r
          r.minimum_quantity = parseInt(r.minimum_quantity) || 0
          return r
        })
        params.price_berjenjang = this.$ArrayToStr(params.price_berjenjang)
        this.$store.dispatch('product/PRODUCT_ITEM_PROCESS', params)
          .then((res) => {
            if (res.status) {
              this.$store.dispatch('TOAST', { show: true, message: this.dialogProductItem.data.id ? 'Berhasil di ubah!' : 'Berhasil di simpan!' })
              this.PRODUCT_GET(this.tableSearch)
              this.dialogProductItem.show = false
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
            }
          })
      } else {
        if (this.dialogProductItem.index > -1) {
          // this.dialogProduct.items[this.dialogProductItem.index] = Object.assign({}, this.dialogProductItem.data)
          const params = Object.assign({}, this.dialogProductItem.data)
          params.galleries = this.$ArrayToStr(params.galleries)
          params.tags = this.$ArrayToStr(params.tags)
          params.price_berjenjang = params.price_berjenjang.map((r) => {
            r = typeof r === 'string' ? this.$StrToJson(r) : r
            r.minimum_quantity = parseInt(r.minimum_quantity) || 0
            return r
          })
          params.price_berjenjang = this.$ArrayToStr(params.price_berjenjang)
          this.dialogProduct.items[this.dialogProductItem.index] = params
        } else {
          // this.dialogProduct.items.push(this.dialogProductItem.data)
          const params = Object.assign({}, this.dialogProductItem.data)
          params.galleries = this.$ArrayToStr(params.galleries)
          params.tags = this.$ArrayToStr(params.tags)
          params.price_berjenjang = this.$ArrayToStr(params.price_berjenjang)
          this.dialogProduct.items.push(params)
        }
        this.dialogProductItem.show = false
      }
    },
    async PRODUCT_VIEW (p) {
      let product = {
        id: null,
        category: '',
        categorysub: '',
        name: '',
        description: '',
        galleries: [],
        stores: [],
        best_seller: 0,
        featured: 0,
        items: [],
        tags: [],
        status: 1
      }
      this.dialogProduct.items = []
      if (p) {
        product = Object.assign({}, p)
        product.stores = product.stores ? this.$StrToArray(product.stores) : []
        product.tags = this.$StrToArray(product.tags)
        product.galleries = this.$StrToArray(product.galleries)
        await this.$store.dispatch('product/PRODUCT_GET_ONE', p.product)
          .then((res) => {
            if (res.status) {
              product = Object.assign({}, res.data.data)
              product.stores = product.stores ? this.$StrToArray(product.stores) : []
              product.galleries = this.$StrToArray(product.galleries)
              product.tags = this.$StrToArray(product.tags)
              this.dialogProduct.items = product.items.map(r => {
                // r.galleries = this.$StrToArray(r.galleries)
                // r.tags = this.$StrToArray(r.tags)
                return r
              })
              delete product.items
            }
          })
      }
      this.dialogProduct.data = product
      this.dialogProduct.show = true
    },
    PRODUCT_PROCESS () {
      if (!this.dialogProduct.data.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama produk harus di isi!' })
        return false
      }
      if (!this.dialogProduct.data.description) {
        this.$store.dispatch('TOAST', { show: true, message: 'Keterangan produk harus di isi!' })
        return false
      }
      const params = {
        product: Object.assign({}, this.dialogProduct.data),
        items: this.dialogProduct.items || []
      }
      params.product.stores = params.product.stores ? params.product.stores.length ? this.$ArrayToStr(params.product.stores) : null : null
      params.product.tags = this.$ArrayToStr(params.product.tags)
      params.product.galleries = this.$ArrayToStr(params.product.galleries)
      if (!params.items.length) {
        this.$store.dispatch('TOAST', { show: true, message: 'Belum ada item ditambahkan!' })
        return false
      } else {
        params.items.map(r => {
          // r.price_berjenjang = (!r.price_berjenjang || typeof r.price_berjenjang === 'string') ? r.price_berjenjang : this.$ArrayToStr(r.price_berjenjang)
          // r.price_berjenjang = this.$ArrayToStr(r.price_berjenjang)
          // r.galleries = this.$ArrayToStr(r.galleries)
          // r.tags = this.$ArrayToStr(r.tags)
          return r
        })
      }
      this.loadingProcess = true
      this.$store.dispatch('product/PRODUCT_PROCESS', params)
        .then((res) => {
          this.loadingProcess = false
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil!' })
            this.PRODUCT_GET(this.tableSearch || '')
            this.dialogProduct.show = false
          }
        })
    },
    PRODUCT_GET (q, searchOnly) {
      if (searchOnly) {
        this.$store.dispatch('product/PRODUCT_GET', ('?store=' + this.$store.state.storeActive) + '&q=' + (q || '') + '&page=1&limit=12&status=1').then((res) => {
          if (res.status) {
            this.dialogStockRequestItem.result = res.data.data.data || []
          } else {
            this.dialogStockRequestItem.result = []
          }
        })
      } else {
        // const status = this.tableSearchStatus
        let withStatus = ''
        if (parseInt(this.productStatus) === 0 || parseInt(this.productStatus) === 1) {
          withStatus = '&status=' + parseInt(this.productStatus)
        }
        this.tableLoading = true
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const query = '?c=' + ('&store=' + this.$store.state.storeActive) + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
        this.$store.dispatch('product/PRODUCT_GET', query + (q ? ('&q=' + q) : '') + withStatus).then((res) => {
          if (res.status) {
            this.table = res.data.data.data
            this.tableTotal = parseInt(res.data.data.total) || 0
          } else {
            this.table = []
            this.tableTotal = 0
          }
          this.tableLoading = false
        })
      }
    },
    async STOCK_REQUEST_VIEW (stockRequestID) {
      let stockRequest = {
        id: null,
        user_requestor: this.$store.state.user.id,
        user_approval: 0,
        store_from: this.$store.state.storeActive,
        store_to: 0,
        title: '',
        items: [],
        status: 0
      }
      this.dialogStockRequest.items = []
      if (stockRequestID) {
        await this.$store.dispatch('product/STOCK_REQUEST_GET_ONE', stockRequestID)
          .then((res) => {
            if (res.status) {
              stockRequest = Object.assign({}, res.data.data)
              stockRequest.store_from = stockRequest.items[0].store_from || 0
              stockRequest.store_to = stockRequest.items[0].store_to || 0
              stockRequest.status = parseInt(stockRequest.status) || 0
              this.dialogStockRequest.items = stockRequest.items || []
              delete stockRequest.items
            }
          })
      }
      this.dialogStockRequest.data = stockRequest
      this.dialogStockRequest.show = true
    },
    ADD_STOCK_REQUEST_ITEM () {
      if (!this.dialogStockRequestItem.product) {
        this.$store.dispatch('TOAST', { show: true, message: 'Produk belum di pilih!' })
        return false
      }
      const findExist = this.dialogStockRequest.items.find(r => r.product_spec === this.dialogStockRequestItem.product.id)
      if (findExist) {
        this.$store.dispatch('TOAST', { show: true, message: 'Produk sudah berada didalam list!' })
        return false
      }
      if (!this.dialogStockRequestItem.quantity) {
        this.$store.dispatch('TOAST', { show: true, message: 'Jumlah produk belum di isi!' })
        return false
      }
      const params = {
        id: null,
        stock_request: null,
        store_from: this.$store.state.storeActive,
        store_to: this.dialogStockRequest.store_to || 0,
        product: Object.assign({}, this.dialogStockRequestItem.product),
        product_sku: this.dialogStockRequestItem.product.sku,
        product_fullname: this.dialogStockRequestItem.product.product_fullname,
        product_spec: this.dialogStockRequestItem.product.id,
        quantity: this.dialogStockRequestItem.quantity || 0,
        status: 0
      }
      this.dialogStockRequest.items.push(params)
      this.dialogStockRequestItem.product = null
      this.dialogStockRequestItem.result = ''
      this.dialogStockRequestItem.quantity = 0
      this.dialogStockRequestItem.query = ''
    },
    STOCK_REQUEST_PROCESS (status) {
      if (!confirm('Apakah anda sudah yakin ?')) {
        return false
      }
      if (!(this.dialogStockRequest.data.store_to > -1)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Toko tujuan belum di pilih!' })
        return false
      }
      if (!this.dialogStockRequest.data.title) {
        this.$store.dispatch('TOAST', { show: true, message: 'Catatan belum di isi!' })
        return false
      }
      if (!this.dialogStockRequest.items.length) {
        this.$store.dispatch('TOAST', { show: true, message: 'Belum ada produk yang ditambahkan!' })
        return false
      }
      if (status === 2 && this.dialogStockRequest.data.store_from && this.dialogStockRequest.data.store_to) {
        const notYetResponded = this.dialogStockRequest.items.filter(r => !parseInt(r.status))
        if (notYetResponded.length) {
          this.$store.dispatch('TOAST', { show: true, message: 'Masih terdapat produk yang belum direspon!' })
          return false
        }
      }
      const params = {
        stock_request: Object.assign({}, this.dialogStockRequest.data),
        items: this.dialogStockRequest.items.map((r) => {
          r.store_from = this.dialogStockRequest.data.store_from || 0
          r.store_to = this.dialogStockRequest.data.store_to || 0
          if (!r.store_from || !r.store_to) {
            r.status = 1
          } else if (status === 99) {
            r.status = 0
          }
          return r
        }) || []
      }
      params.stock_request.status = status
      if (!params.items.length) {
        this.$store.dispatch('TOAST', { show: true, message: 'Belum ada produk ditambahkan!' })
        return false
      }
      this.$store.dispatch('product/STOCK_REQUEST_PROCESS', params)
        .then((res) => {
          if (res.status) {
            this.STOCK_REQUEST_GET(this.tableSearchSR || '')
            this.dialogStockRequest.show = false
          }
        })
    },
    STOCK_REQUEST_GET (q) {
      const status = this.tableSearchStatusSR
      this.tableLoadingSR = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsSR
      const query = '?c=' + ('&store=' + this.$store.state.storeActive) + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('product/STOCK_REQUEST_GET', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 1 || parseInt(status) === 0 ? '&status=' + status : '') + ('&type=' + this.tableInOutSR)).then((res) => {
        if (res.status) {
          this.tableSR = res.data.data.data
          this.tableTotalSR = parseInt(res.data.data.total) || 0
        } else {
          this.tableSR = []
          this.tableTotalSR = 0
        }
        this.tableLoadingSR = false
      })
    },
    StatusRequest (stReq, stItem) {
      if (stReq === 0) {
        return {
          response: false,
          message: 'Pending',
          color: 'grey'
        }
      } else if (stReq === 1) {
        if (stItem === 0) {
          return {
            response: true,
            request: {
              color: 'orange',
              message: 'Terproses'
            },
            product: {
              color: 'grey',
              message: 'Pending'
            }
          }
        } else if (stItem === 1) {
          return {
            response: true,
            request: {
              color: 'orange',
              message: 'Terproses'
            },
            product: {
              color: 'green',
              message: 'Diterima'
            }
          }
        } else if (stItem === 2) {
          return {
            response: true,
            request: {
              color: 'orange',
              message: 'Terproses'
            },
            product: {
              color: 'red',
              message: 'Ditolak'
            }
          }
        }
      } else if (stReq === 2) {
        if (stItem === 0) {
          return {
            response: true,
            request: {
              color: 'green',
              message: 'Selesai'
            },
            product: {
              color: 'grey',
              message: 'Pending'
            }
          }
        } else if (stItem === 1) {
          return {
            response: true,
            request: {
              color: 'green',
              message: 'Selesai'
            },
            product: {
              color: 'green',
              message: 'Diterima'
            }
          }
        } else if (stItem === 2) {
          return {
            response: true,
            request: {
              color: 'green',
              message: 'Selesai'
            },
            product: {
              color: 'red',
              message: 'Ditolak'
            }
          }
        }
      } else {
        return {
          response: false,
          message: 'Dibatalkan',
          color: 'red'
        }
      }
    },
    changeGallery (index) {
      if (!confirm('Ganti image ?')) {
        return false
      }
      this.galleryIndexToChange = index
      this.UploadImage()
    },
    removeGallery (index) {
      if (!confirm('Apakah yakin akan dihapus ?')) {
        return false
      }
      this.dialogProductItem.data.galleries.splice(index, 1)
    },
    ImportXlsx () {
      if (!confirm('Apakah anda yakin? Mengimport data akan menghapus data produk yang ada termasuk data transaksi, stock, dll!')) {
        return false
      }
      if (!this.loadingImageUpload) {
        this.galleryIndexToChange = -1
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.xlsx')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e, 'xlsx')
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    UploadImage () {
      // type="file"
      // class="file-uploader"
      // accept=".png,.gif,.jpeg,.jpg"
      // @change=""
      if (!this.loadingImageUpload) {
        this.galleryIndexToChange = -1
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event, type) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: type === 'xlsx' ? 'doc' : 'image'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                if (type === 'xlsx') {
                  $.$store.dispatch('TOAST', { show: true, message: 'Berhasil di import!' })
                  $.$store.dispatch('product/PRODUCT_TAGS_GET')
                  $.PRODUCT_GET($.tableSearch || '')
                } else if ($.galleryIndexToChange > -1) {
                  $.dialogProductItem.data.galleries[$.galleryIndexToChange] = resUpload.data.data.secure_url
                } else {
                  $.dialogProductItem.data.galleries.push(resUpload.data.data.secure_url)
                }
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.gmj-gallery {
  >.gmj-gallery-action {
    transition: opacity .3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    >.gmj-gallery-action {
      visibility: visible;
      pointer-events: all;
      opacity: 1;
    }
  }
}
.table-pbj {
  position: relative;
  min-width: 300px;
  overflow-x: auto;
  .pb-active {
    td {
      background: rgba(1, 4, 182, 0.1);
    }
  }
  td {
    padding: 2px 4px;
  }
  thead {
    td {
      padding: 4px;
      text-align: center;
      font-size: 10px;
      font-weight: bold;
    }
  }
  tbody {
    td {
      border-bottom: solid 1px rgba(0, 0, 0, .1);
    }
  }
}
</style>
